
import { defineComponent } from 'vue';

// Config
import { GTM_URLS_BY_ID } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'EmptyLayout',

  mounted() {
    // load GTM
    const gtmId = this.$partnerConfig ? this.$partnerConfig.gtm_id as string : process.env.NUXT_ENV_GTM_ID as string;
    const scriptURL = GTM_URLS_BY_ID[gtmId];
    this.$bootstrapGTM({ id: gtmId, scriptURL });
  }
});
