import type { Context } from '@white-label-types/middleware';
import type { Auth } from '@nuxtjs/auth-next';
import type { NuxtCookies } from 'cookie-universal-nuxt';

import { isBookingPortal } from '@white-label-helper/is-booking-portal';

interface ContextWithInstalledModules extends Context {
  $auth: Auth;
  $cookies: NuxtCookies;
}

const bookingPortalSSO = async ({
  redirect, query, $auth, $cookies,
}: ContextWithInstalledModules): Promise<unknown> => {
  if (!process.client) {
    return;
  }

  if (isBookingPortal && query.sso) {
    const {
      token, tokenExpiration, modifyBookingPath,
    } = JSON.parse(atob(query.sso as string));

    const strategyName = $auth.$state.strategy;
    let cookiePrefix = '';
    if ($auth.options.cookie) {
      cookiePrefix = $auth.options.cookie.prefix;
    }
    const strategyCookieName = `${cookiePrefix}strategy`;
    const tokenCookieName = `${cookiePrefix}_token.${strategyName}`;
    const tokenExpirationCookieName = `${cookiePrefix}_token_expiration.${strategyName}`;

    try {
      $cookies.set(strategyCookieName, strategyName);
      $cookies.set(tokenCookieName, token);
      $cookies.set(tokenExpirationCookieName, tokenExpiration);

      await $auth.setUserToken(token);
      await $auth.fetchUser();

      if ($auth.loggedIn) {
        const redirectPath = modifyBookingPath ?? '/';
        redirect(redirectPath);
      }
    } catch (error) {
      await $auth.logout();
    }
  }
};

export default bookingPortalSSO;
