import type { Context } from '@white-label-types/middleware';
import type { LDClient, LDContext } from 'launchdarkly-js-client-sdk';
import { initialize } from 'launchdarkly-js-client-sdk';

const context: LDContext = {
  anonymous: true,
  kind: 'user',
};

let launchDarklyClient: LDClient | null = null;

/**
 * Checks if there is an active maintenance mode flag, if so then redirects the user to the `/maintenance` route.
 *
 * For details on router middleware see https://v2.nuxt.com/docs/directory-structure/middleware#router-middleware
 * For details on feature toggles see https://docs.launchdarkly.com/sdk/client-side/javascript#getting-started
 *
 * @param {Context} context
 * @returns {Promise<any>}
 */
const maintenanceModeRouterMiddlware = async ({ redirect, route }: Context): Promise<unknown> => {
  if (process.server) {
    return;
  }

  if (process.env.NUXT_ENV_LAUNCH_DARKLY_CLIENT_ID === undefined) {
    return;
  }

  if (launchDarklyClient === null) {
    launchDarklyClient = initialize(process.env.NUXT_ENV_LAUNCH_DARKLY_CLIENT_ID, context, { streaming: true });
  }

  await launchDarklyClient.waitUntilReady();

  if (route.path !== '/maintenance' && launchDarklyClient.variation('WL-GlobalMaintenanceMode') === true) {
    // NOTE: this eslint rule is disabled as Nuxt expects there to be different return types for router middleware
    // eslint-disable-next-line consistent-return
    return redirect('/maintenance');
  }

  if (route.path === '/maintenance' && launchDarklyClient.variation('WL-GlobalMaintenanceMode') === false) {
    // NOTE: this eslint rule is disabled as Nuxt expects there to be different return types for router middleware
    // eslint-disable-next-line consistent-return
    return redirect('/');
  }
};

//
export default maintenanceModeRouterMiddlware;
